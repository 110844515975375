<template>
  <div
    class="shortBookInformation"
    :class="{ 'shortBookInformation-expert': bookOn === 'expert' }"
  >
    <div
      v-if="!!costRange && !!costRange.min && !!costRange.max"
      class="shortBookInformation__price"
    >
      {{ `${$t("priceRange")} €${costRange.min}-€${costRange.max}` }}
    </div>
    <div class="shortBookInformation__calendar">
      {{ $t("singleServicePageAvailable") }}
    </div>
    <base-button
      :class="{ 'shortBookInformation-disable': loginUser.role_id === 1 }"
      @click="addToBook()"
      :calendar="true"
    >
      <template #icon>
        <calendar-icon />
      </template>
      {{ $t("headerCalendarBtn") }}
    </base-button>
  </div>
</template>

<script>
import BaseButton from "@/components/UI/buttons/BaseButton";
import CalendarIcon from "@/components/UI/icons/CalendarIcon";
import { mapGetters } from "vuex";

export default {
  props: {
    information: Object,
    costRange: Object,
    bookOn: String,
  },
  name: "ShortBookInformation",
  components: { CalendarIcon, BaseButton },
  methods: {
    addToBook() {
      if (this.bookOn === "expert") {
        this.information.user_id = this.information.id;
        this.$store.commit("SET_BOOK_DATA_EXPERT", this.information);
        this.$router.push("/calendar");
        return;
      }
      if (this.bookOn === "service") {
        this.$store.commit("SET_BOOK_DATA_SERVICES", this.information);
        this.$router.push("/calendar");
      }
    },
  },
  computed: {
    ...mapGetters({
      loginUser: "CURRENT_USER_INFORMATION",
    }),
  },
};
</script>

<style></style>
