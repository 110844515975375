<template>
  <main-layout>
    <div class="d-flex align-items-center">
      <base-button @click="$router.back()" :back="true">
        <horizontal-arrow />
      </base-button>
      <h1 class="m-0 ms-3">{{ service.name }}</h1>
    </div>
    <div class="serviceSinglePage__content">
      <div class="serviceSinglePage__image">
        <image-service
          v-if="service.image"
          size="1000"
          :single-service="true"
          :service_slug="service.slug"
          :image-name="service.image"
        />
      </div>
      <div class="serviceSinglePage__text-container">
        <div class="serviceSinglePage__text">
          <div id="text">
            <div id="textContent" v-html="serviceDescriptions"></div>
          </div>
          <div
            v-if="showReadMoreBtn"
            class="expertSinglePage__read serviceSinglePage__read"
            @click="showFullDescription = !showFullDescription"
          >
            {{ showFullDescription ? $t("hideBtn") : $t("readMoreBtn") }}
            <div
              class="transition"
              :class="{ 'serviceSinglePage__read-icon': showFullDescription }"
            >
              <horizontal-arrow />
            </div>
          </div>
        </div>
        <short-book-information
          book-on="service"
          :information="service"
          :cost-range="servicePriceRange"
          class="m-0"
        />
      </div>
    </div>
    <div v-if="haveExperts">
      <h2>{{ $t("singleServicePageProvideExpert") }}</h2>
      <div class="row">
        <div
          class="col-12 col-lg-6 mb-20"
          v-for="expert in service.experts"
          :class="{ 'pointer-event-none': loginUser.role_id === 1 }"
          :key="expert.user_id"
        >
          <expert-card :expert-information="expert" />
        </div>
      </div>
    </div>
  </main-layout>
</template>

<script>
import MainLayout from "@/layouts/MainLayout";
import BaseButton from "@/components/UI/buttons/BaseButton";
import HorizontalArrow from "@/components/UI/icons/arrows/HorizontalArrow";
import ShortName from "@/components/components-helpers/ShortName";
import ImageService from "@/components/UI/images/ImageService";
import ExpertCard from "@/components/elements/card/ExpertCard";
import ShortBookInformation from "@/components/elements/card/ShortBookInformation";
import { mapGetters } from "vuex";

export default {
  name: "ServiceSinglePage",
  components: {
    ShortBookInformation,
    ExpertCard,
    ImageService,
    ShortName,
    HorizontalArrow,
    BaseButton,
    MainLayout,
  },
  data() {
    return {
      service: [],
      servicePriceRange: null,
      showFullDescription: false,
    };
  },
  async mounted() {
    this.service = await this.getServiceInformation();
    this.servicePriceRange = await this.getServicePriceRange(this.service.id);
  },
  methods: {
    async getServiceInformation() {
      return this.$http
        .get("services/slug/" + this.$route.params.service, {})
        .then(({ data }) => {
          return data.body;
        });
    },
    async getServicePriceRange(serviceId) {
      return this.$http
        .get(`consultations/service/${serviceId}/price-range`)
        .then(({ data }) => {
          return data.body;
        });
    },
  },
  computed: {
    ...mapGetters({
      loginUser: "CURRENT_USER_INFORMATION",
    }),
    haveExperts() {
      return !!this.service.experts ? this.service.experts.length > 0 : false;
    },
    showReadMoreBtn() {
      return !!this.service.description
        ? this.service.description.length > 500
        : false;
    },
    serviceDescriptions() {
      if (!!this.service.description) {
        if (this.showFullDescription) {
          return this.service.description;
        } else {
          return this.service.description.length > 500
            ? this.service.description.substring(0, 500) + "..."
            : this.service.description.substring(0, 300);
        }
      } else {
        return "";
      }
    },
  },
};
</script>

<style></style>
